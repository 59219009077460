import {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    Collapse,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

const useStyles: any = makeStyles({
    root: {
        maxWidth: 800,
        margin: 'auto',
        marginTop: '40px',
        marginBottom: '40px',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    icon: {
        color: 'red',
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto'
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
});

const contest = {
    "_id": "63a00456d61598ee56e7563f",
    "type": "story_writing_contest",
    "title": "#WriteForLove ",
    "slug": "write-for-love",
    "desc": "Write for love story writing contest",
    "thumbnail_url": "https://unlu-general.s3.ap-south-1.amazonaws.com/mugafi_landing_page/images/WriteForLove_v1.jpg",
    "registration_amount": 699,
    "start_date": "2022-12-19T13:36:18.860Z",
    "end_date": "2022-12-19T13:36:18.860Z",
    "deleted": false,
    "ui_metadata": {
        "contest_extra_meta_v2": [
            {
                "type": "header_content",
                "banner_image_url": "https://unlu-general.s3.ap-south-1.amazonaws.com/mugafi_landing_page/images/WriteForLove_v1.jpg",
                "mweb_banner_image_url": "https://unlu-general.s3.ap-south-1.amazonaws.com/mugafi_landing_page/images/WriteForLove-M_v1.jpg"
            },
            {
                "type": "contest_features",
                "class_features": [
                    {
                        "icon": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/GetPublished.png",
                        "title": "Get Published",
                        "sub_title": "Top 20 love stories will be published in Ruskin Bond’s next book"
                    },
                    {
                        "icon": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Avail100%25Scholarship.png",
                        "title": "Avail 100% Scholarship",
                        "sub_title": "Top 20 storywriters will receive full scholarship on Mugafi Writing Programs"
                    },
                    {
                        "icon": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/JoinCreatorsCommunity.png",
                        "title": "Join Our Community",
                        "sub_title": "Top 20 storywriters will get access to be a part of our growing community"
                    },
                    {
                        "icon": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Collaborate_create.png",
                        "title": "Collaborate & Create",
                        "sub_title": "Get access to creator modes and collaborate with the writers of our community"
                    }
                ]
            },
            {
                "type": "contest_details",
                "image_url": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Contestdetails.png",
                "community_features": [
                    {
                        "topic": "Theme",
                        "desc": "a love story that transcends the boundaries of conventional relationships"
                    },
                    {
                        "topic": "Language",
                        "desc": "your love story should be written in English only"
                    },
                    {
                        "topic": "Word Limit",
                        "desc": "your love story should be written between 750 to 1000 words"
                    }
                ]
            },
            {
                "type": "modules_and_features",
                "module_pdf_link": "",
                "modules": [
                    {
                        "video_url": "",
                        "thumbnail_url": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Credibility.png",
                        "key_points": [
                            "Helps you in discovering your mojo",
                            "Helps you in overcoming the writer's block",
                            "Inspires you with newer ideas for your future write-ups"
                        ]
                    },
                    {
                        "video_url": "",
                        "thumbnail_url": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Inspiration.png",
                        "key_points": [
                            "You get a chance to expand your reader base",
                            "You get to add more to your writing resume ",
                            "Get the ultimate recognition of Ruskin Bond "
                        ]
                    },
                    {
                        "video_url": "",
                        "thumbnail_url": "https://unluclass.s3.ap-south-1.amazonaws.com/thumbnails/contest/Exposure.png",
                        "key_points": [
                            "Get to meet and connect with more writers ",
                            "Learn new ways to build your writing career",
                            "Discover potential mentors in the process"
                        ]
                    }
                ]
            },
            {
                "type": "community",
                "image_url": "https://unlu-general.s3.ap-south-1.amazonaws.com/mugafi_landing_page/images/writeforlove_certificates.png",
                "community_features": [
                    {
                        "topic": "Think",
                        "desc": "of all the beautiful love stories you can create"
                    },
                    {
                        "topic": "Write",
                        "desc": "to know what magic you are capable of"
                    },
                    {
                        "topic": "Share",
                        "desc": "to bring your stories from a first draft to a published piece"
                    }
                ]
            },
            {
                "type": "work_opportunities",
                "graphic_image": "https://unluclass.s3.ap-south-1.amazonaws.com/fellowship/opportunities/writer.png",
                "title": "let your stories inspire millions"
            },
            {
                "type": "costing",
                "actual_price": "1900",
                "discounted_price": "2000",
                "emi_price": 4999
            },
            {
                "type": "faqs",
                "faqs": [
                    {
                        "question": "Can I submit a previously published story for the #WriteForLove contest?",
                        "answer": [
                            "You can't submit previously published stories for the #WriteForLove contest."
                        ]
                    },
                    {
                        "question": "What is the language requirement for the #WriteForLove contest entries?",
                        "answer": [
                            "We are only looking for stories in English."
                        ]
                    },
                    {
                        "question": "Can I submit my #WriteForLove contest entry in a different format like a video or audio recording?",
                        "answer": [
                            "Please submit your stories in text format only."
                        ]
                    },
                    {
                        "question": "What happens to the copyright of my #WriteForLove contest entry if it is selected for publication in Ruskin Bond's next book?",
                        "answer": [
                            "The copyright of the story is with its writer."
                        ]
                    },
                    {
                        "question": "Can I withdraw my #WriteForLove contest entry after submitting it?",
                        "answer": [
                            "No, once you've submitted your entry for the contest there's no option to withdraw."
                        ]
                    },
                    {
                        "question": "How will the winners be informed about their selection for publication in Ruskin Bond's next book?",
                        "answer": [
                            "The winners will be announced on our social media. We will reach out to the winners on the emails attached in the submission."
                        ]
                    },
                    {
                        "question": "Is it possible to get feedback on my #WriteForLove contest entry if it is not selected for publication?",
                        "answer": [
                            "No, we will not be sharing feedback on the stories submitted for the contest."
                        ]
                    },
                    {
                        "question": "Can I submit my #WriteForLove contest entry under a pseudonym?",
                        "answer": [
                            "Yes, you may enter under a pen name. Include your pen name on all forms as you would wish for them to appear in any credits.",
                            "Please be advised (and advise us of it if you are notified as a winner) that we only need your legal name if you are chosen as a winner (in order to issue prizes)."
                        ]
                    },
                    {
                        "question": "Is there a word limit for each #WriteForLove contest entry?",
                        "answer": [
                            "Yes, your love story should be between 750-1000 words."
                        ]
                    },
                    {
                        "question": "Can I submit a co-authored #WriteForLove contest entry?",
                        "answer": [
                            "No, you can't submit a co-authored story as a contest entry."
                        ]
                    },
                    {
                        "question": "Will the judges be considering the popularity of entries on social media or other platforms?",
                        "answer": [
                            "No, the judges will not be considering the popularity of entries on any social media channels."
                        ]
                    },
                    {
                        "question": "How many entries can one participant submit for the #WriteForLove contest?",
                        "answer": [
                            "You can submit multiple entries if you want. For each individual entry you'll have to pay INR 699. All the entries will be thoroughly read, evaluated and considered for the contest."
                        ]
                    },
                    {
                        "question": "Who can participate in the #WriteForLove contest?",
                        "answer": [
                            "Anyone with a love-story in English can participate in the contest."
                        ]
                    },
                    {
                        "question": "What is the deadline for submitting entries for the #WriteForLove contest?",
                        "answer": [
                            "Participants have to submit their story before 31 May 2023, 11:59pm IST"
                        ]
                    },
                    {
                        "question": "When will the winners of the #WriteForLove contest be announced?",
                        "answer": [
                            "The result will be announced tentatively in the month of July as we have extended the last date of the contest to May 31st."
                        ]
                    },
                    {
                        "question": "Is the entry fee refundable if my entry is not selected?",
                        "answer": [
                            "The registration/Entry fee will not be refunded if your entry is not selected."
                        ]
                    },
                    {
                        "question": "Whom can I contact if my query is not listed here?",
                        "answer": [
                            "If your query is not listed above drop us an email at hello@mugafi.com",
                            "Or contact us at +919289466693"
                        ]
                    }
                ]
            },
            {
                "type": "next_demo"
            }
        ],
        "ui_listing_meta": {
            "background_gradient": {
                "start_color": "#FEE2BE",
                "end_color": "#FEE2BE"
            },
            "fellowship_theme_color": "#7D24C7"
        }
    }
}

function FileUpload() {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState(contest);

    const handleEditClick = () => {
        setIsEditing(true);
    };

    const handleSaveClick = () => {
        setIsEditing(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.root}>
            <CardHeader
                title={
                    isEditing ? (
                        <input
                            type="text"
                            name="title"
                            value={editedData.title}
                            onChange={handleInputChange}
                        />
                    ) : (
                        editedData.title
                    )
                }
                subheader={
                    isEditing ? (
                        <input
                            type="text"
                            name="registration_amount"
                            value={editedData.registration_amount}
                            onChange={handleInputChange}
                        />
                    ) : (
                        `Registration amount: ₹${editedData.registration_amount}`
                    )
                }
                action={
                    isEditing ? (
                        <IconButton onClick={handleSaveClick}>
                            <SaveIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={handleEditClick}>
                            <EditIcon />
                        </IconButton>
                    )
                }
            />
            <CardMedia
                className={classes.media}
                image={contest.thumbnail_url}
                title={contest.title}
            />
            <CardContent>
                <Typography variant="body1" color="textSecondary" component="p">
                    {contest.desc}
                </Typography>
                <List>
                    {contest.ui_metadata?.contest_extra_meta_v2?.[2]?.community_features?.map((feature) => (
                        <ListItem key={feature.topic}>
                            <ListItemIcon className={classes.icon}>
                                <CheckCircleIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={feature.topic}
                                secondary={feature.desc}
                            />
                        </ListItem>
                    ))}
                </List>
                <IconButton
                    className={`${classes.expand} ${expanded && classes.expandOpen}`}
                    onClick={handleExpandClick}
                    aria-expanded={expanded}
                    aria-label="show more"
                >
                    <ExpandMoreIcon/>
                </IconButton>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph variant="body2">
                        Modules:
                    </Typography>
                    <List>
                        {contest.ui_metadata?.contest_extra_meta_v2[3]?.modules?.map((module) => (
                            <ListItem key={module.thumbnail_url}>
                                <ListItemIcon>
                                    <img
                                        src={module.thumbnail_url}
                                        alt={module.key_points[0]}
                                        width="64"
                                        height="64"
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    primary={module.key_points[0]}
                                    secondary={
                                        <>
                                            {module.key_points.slice(1).map((point) => (
                                                <Typography
                                                    key={point}
                                                    component="span"
                                                    variant="body2"
                                                    color="textSecondary"
                                                >
                                                    {point}
                                                    <br/>
                                                </Typography>
                                            ))}
                                        </>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            </Collapse>
        </Card>
    );
}

export default FileUpload;
